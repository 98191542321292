import { doc } from './utils/Document'
import initEvents from './events/index'
import PicReveal from './modules/PicReveal'

class App {
  constructor() {
    this.init()
  }

  initRevealers() {
    this.revealers = Array.from(
      document.getElementsByClassName('revealer'),
      el => new PicReveal(el)
    )
  }

  init() {
    doc.classList.remove('no-js')
    doc.classList.add('js')

    initEvents(this)
    this.initRevealers()
  }
}

new App()
