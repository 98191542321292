import { passiveOnceEvent } from '../../utils/EventListenerOptions'
import { clamp } from '../../utils/math'
import { pxToRem } from '../../utils/units'

const THUMB_MIN_DISTANCE_FROM_EDGE = 15

class PicReveal {
  constructor(picEl) {
    this.el = picEl
    this.picsStillLoading = 2

    this.init()
  }

  // Update before/after thumb position in px.

  setClip(offsetInPx) {
    offsetInPx = clamp(offsetInPx, THUMB_MIN_DISTANCE_FROM_EDGE, this.stickyRightOffset)

    this.el.style.setProperty('--clip', pxToRem(offsetInPx))
  }

  calculate() {
    // Waiting all pics to be loaded prevents bad value in this.calculate().
    if (this.picsStillLoading) { return }

    this.el.classList.remove('revealer--not-ready')
    this.el.classList.add('revealer--ready')

    this.w = this.el.clientWidth
    this.ctnOffsetX = this.el.offsetLeft
    this.stickyRightOffset = this.w - THUMB_MIN_DISTANCE_FROM_EDGE

    this.el.style.setProperty('--pic-w', pxToRem(this.w))
    this.el.style.setProperty('--pic-h', pxToRem(this.el.clientHeight))

    this.setClip(this.w / 2)
  }

  // Flag pictures not loaded yet.

  findPicsStillLoading() {
    const unloadedPics = [...this.el.querySelectorAll('img')]
      .filter(pic => !pic.complete || !pic.naturalHeight)

    this.picsStillLoading = unloadedPics.length

    unloadedPics.forEach(pic => {
      pic.addEventListener('load', e => {
        this.picsStillLoading--
        this.calculate()
      }, passiveOnceEvent)
    })
  }

  onResize() { this.calculate() }

  initEvents () {
    this.el.addEventListener('mousemove', e => this.setClip(e.offsetX))

    this.el.addEventListener('touchmove',
      e => this.setClip(e.targetTouches[0].clientX - this.ctnOffsetX)
    )
  }

  init() {
    this.findPicsStillLoading()
    this.calculate()
    this.initEvents()
  }
}

export default function(picEl) {
  return new PicReveal(picEl)
}
