import { captureEvent } from '../utils/EventListenerOptions'

class EventsManager {
  constructor(app) {
    this.app = app
    this.resizeTimer = null

    this.init()
  }

  onResize() {
    clearTimeout(this.resizeTimer)
    document.body.classList.add('resizing')

    this.resizeTimer = setTimeout(() => {
      document.body.classList.remove('resizing')
      this.app.revealers.forEach(revealer => revealer.onResize())
    }, 400)
  }

  init() {
    window.addEventListener('resize', this.onResize.bind(this))

    // add :hover support in iOS ¯\_(ツ)_/¯
    document.addEventListener('touchstart', () => {})
  }
}

export default function(app) {
  return new EventsManager(app)
}
